<template>
    <div class="box">
        <div class="body">
            <div class="title">收藏网址</div>
            <div>
                <el-input v-model="form.title" placeholder="请输入标题"></el-input>
            </div>
            <div>
                <el-input v-model="form.url" placeholder="请输入网址"></el-input>
            </div>
            <div>
                <el-select style="width: 100%"
                           v-model="form.pid"
                           filterable
                           remote
                           placeholder="请选择文件夹（输入名称搜索）"
                           :remote-method="remoteMethod"
                           :loading="loading">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="buttons">
                <el-button @click="handleClose()">取消</el-button>
                <el-button type="primary" @click="handleSave">保存</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {types} from '@/utils/config.js'
    import {apiPost} from "../../utils/api";

    export default {
        name: "url",
        data() {
            return {
                form: {
                    id: 0,
                    pid: '',
                    type: types.url,
                    title: "",
                    content: "",
                    format: "",
                    url: "",
                },
                loading: false,
                options: []
            }
        },
        created() {
            this.form.title = this.$route.query.title || ''
            this.form.url = this.$route.query.url || ''
            document.body.style = 'background-color:transparent'
        },
        mounted() {
            this.$store.dispatch("loadNotes")
        },
        methods: {
            remoteMethod(query) {
                if (query) {
                    let reg = new RegExp(query, 'i')
                    let results = this.$store.state.notes.filter(v => (reg.test(v.title) && v.type == types.folder))
                    console.log(reg)
                    console.log(results)
                    console.log(this.$store.state.notes)
                    this.options = results.map(v => ({value: v.id, label: v.title}))
                } else {
                    this.options = []
                }
            },

            handleSave() {
                apiPost('/note', this.form).then(result => {
                    if (result.code === 0) {
                        this.$store.dispatch("loadNotes")
                        this.$message.success('已收藏')
                        this.handleClose(1000)
                    }
                })
            },

            handleClose(delay) {
                setTimeout(() => {
                    window.parent.postMessage({action: 'add.url.close'}, '*')
                }, delay || 0)
            },

        }
    }
</script>

<style scoped>
    .box {
        display: flex;
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.22);
        justify-content: center;
        align-items: center;
    }

    .title {
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif;
        -webkit-font-smoothing: antialiased;
        -webkit-tap-highlight-color: transparent;
        font-size: 28px;
        margin: 0;
        font-weight: 400;
        color: #1f2f3d;
    }

    .body {
        border: 1px solid #ebebeb;
        border-radius: 6px;
        transition: .2s;

        width: 660px;
        background: #FFF;
        box-sizing: border-box;
        padding: 26px 26px 0;
    }

    .body > div {
        padding-bottom: 26px;
    }

    .buttons {
        text-align: right;
    }
</style>
